"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CellComponentNames = exports.ColArrayPipeNames = exports.ColNumberPipeNames = exports.ColStringPipeNames = void 0;
var ColStringPipeNames;
(function (ColStringPipeNames) {
  ColStringPipeNames["TRANSLATE"] = "translate";
  ColStringPipeNames["ENUM_TRANSLATE"] = "enumTranslate";
  ColStringPipeNames["ELLIPSIS"] = "ellipsis";
  ColStringPipeNames["TRUNCATE"] = "truncate";
  ColStringPipeNames["DATE"] = "date";
  ColStringPipeNames["FORMATTER"] = "formatter";
  ColStringPipeNames["REGION"] = "region";
})(ColStringPipeNames || (exports.ColStringPipeNames = ColStringPipeNames = {}));
var ColNumberPipeNames;
(function (ColNumberPipeNames) {
  ColNumberPipeNames["PRECISION"] = "precision";
  ColNumberPipeNames["FORMATTER"] = "formatter";
})(ColNumberPipeNames || (exports.ColNumberPipeNames = ColNumberPipeNames = {}));
var ColArrayPipeNames;
(function (ColArrayPipeNames) {
  ColArrayPipeNames["REGION"] = "region";
  ColArrayPipeNames["TRANSLATE"] = "translate";
  ColArrayPipeNames["ARRAY_TO_STRING"] = "arrayToString";
})(ColArrayPipeNames || (exports.ColArrayPipeNames = ColArrayPipeNames = {}));
var CellComponentNames;
(function (CellComponentNames) {
  CellComponentNames["USER_NAME_CELL_COMPONENT"] = "USER_NAME_CELL_COMPONENT";
})(CellComponentNames || (exports.CellComponentNames = CellComponentNames = {}));
